import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Profile } from '../Api/Profile';
import { Toast } from '../hooks/useToast';
import { updateKey } from './DefaultReducer';


export const postProfileThunk = createAsyncThunk(
    'profile',
    async (data, { dispatch }) => {
        Toast("Validating Credentials", "loading", true);
        try {
            const response = await Profile.post(data);
            Toast(response.message, "success", false);
            dispatch(updateKey({ isLoggedIn: true }));
            return response.data
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message)
        }
    }
)

export const postForgetpasswordThunk = createAsyncThunk(
    'profile',
    async ({ email, setLoginForm }, { dispatch }) => {
        Toast("Sending Email", "loading", true);
        try {
            const response = await Profile.postForgetPassword({ email });
            Toast(response.message, "success", false);
            setLoginForm(true);
            return {}
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message)
        }
    }
)


const initialState = {
    status: "pending",
    data: {
    },
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setStatus: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
        resetState: () => {
            return initialState
        }
    },
    extraReducers: {
        [postProfileThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [postProfileThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [postProfileThunk.rejected]: (state, action) => {
            state.status = "error"
        },
    }
})


// Action creators are generated for each case reducer function
export const { setStatus, resetState } = profileSlice.actions

export default profileSlice.reducer