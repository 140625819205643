
import React, { useEffect, useState, useRef } from "react";


/**Import Styles And Assets */
import styles from "./styles/index.module.scss";
import preview from "./images/preview.png"


import { addLanguageThunk, deleteLanguageThunk, getLanguageThunk } from "../../features/lookupReducer";
import { Lesson } from "../../Api/Lesson";
import { validateType } from "../../Prototypes";
import { Toast } from "../../hooks/useToast";


/**Import Package Components */
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { Select, Input } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import { GrFormAdd } from "react-icons/gr";
import { AiOutlineMinus } from "react-icons/ai";
import { updateStatus } from "../../features/DashboardReducer";
import fileDownload from "js-file-download";

const { Option } = Select
const { Dragger } = Upload;


export default function UploadLesson() {
    const dispatch = useDispatch();
    const categoryRef = useRef();
    const { data, status } = useSelector(state => state.lookup.language)
    const { api_token } = useSelector(state => state.profile.data)
    const [file, setFile] = useState('');
    const [book, setBook] = useState("");
    const [bookname, setBookname] = useState("");
    const [language, setLanguage] = useState(null);
    const [isCategorySubmitted, setIsCategorySubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [downloadLesson, setDownloadLesson] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);

    const dummyRequest = async ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }

    const props = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        className: styles.fields,
        customRequest: dummyRequest,
        onChange: (info) => {
            if (!validateType(info.file.type)) {
                return message.error('File type not supported');
            }
            setFile(info.file);
            return
        },
        beforeUpload: () => false,
        accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    };


    useEffect(() => {
        if (status !== 'success') {
            dispatch(getLanguageThunk())
        }
    }, [])





    const handleUploadfile = async () => {
        if (isSubmitted) return;
        Toast("Uploading File", 'loading', true);
        setIsSubmitted(true);

        const data = new FormData();
        data.append('file', file);
        data.append('language', language ? language : '');
        data.append('book', book);
        data.append('bookname', bookname)

        try {
            const response = await Lesson.upload({ data, api_token });
            Toast('Lesson added successfully', 'success', false);
            setBook('');
            setBookname('');
            setFile('');
            setLanguage('')
            dispatch(updateStatus("updated"))
            setDownloadLesson(response.data);

        }
        catch (err) {
            Toast(err.message, 'error', false)
        }
        finally {
            setIsSubmitted(false);
        }
    }

    const handleChange = (value, cb) => {
        cb(value);
    }



    const handleAddCategory = () => {
        const { value } = categoryRef.current
        if (!value) return Toast('Language Should Not Be Empty', 'error', false);
        setIsCategorySubmitted(true);
        dispatch(addLanguageThunk({ data: { name: value }, setIsCategorySubmitted, categoryRef }))
        dispatch(updateStatus("updated"))


    }


    const deleteItem = (e, value) => {
        e.stopPropagation();
        e.preventDefault();
        if (isDeleting) return;

        setIsDeleting(true);
        dispatch(deleteLanguageThunk({ slug: value, setIsDeleting }));
    };


    const handleDownloadLesson = async () => {

        if (isDownloading) return;
        setIsDownloading(true);
        try {
            const response = await Lesson.download({ path: downloadLesson.path });
            fileDownload(JSON.stringify(response), downloadLesson.bookname);
        }
        catch (e) {
            Toast(e.message, 'error', false)
        }
        finally {
            setIsDownloading(false);
        }
    }


    return (
        <div className={styles.uploadLessons}>
            <div className={styles.headerContainer}>
                <h1 className={styles.contentTitle}>Lesson Management</h1>
                {downloadLesson ? <button className={styles.headerButton} onClick={handleDownloadLesson}>
                    <span>Download</span>
                    <span className={styles.name}>{downloadLesson.bookname}</span>
                </button> : ''}
            </div>
            <div className={styles.content}>
                <div style={{ position: 'relative', width: "100%" }} id="area">
                    <Select
                        className={styles.select}
                        popupClassName={styles.popup}
                        placeholder={<p className={styles.placeholder}>Select Language</p>}
                        getPopupContainer={() => document.getElementById('area')}
                        loading={status == 'loading'}
                        notFoundContent={<span></span>}
                        onChange={(val) => handleChange(val, setLanguage)}
                        value={language}
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <hr />
                                <div className={styles.addCategoryContainer}>
                                    <input
                                        type="text"
                                        placeholder="Enter Language..."
                                        className={styles.addCategoryInput}
                                        ref={categoryRef}

                                    />
                                    {isCategorySubmitted ?
                                        <button
                                            className={styles.addCategory}
                                            type="button"
                                            onClick={() => { }}
                                        ><LoadingOutlined style={{ fontSize: 24 }} spin />
                                        </button> :
                                        <button
                                            className={styles.addCategory}
                                            type="button"
                                            onClick={handleAddCategory}
                                        ><GrFormAdd />
                                        </button>

                                    }

                                </div>
                            </>
                        )}
                    >
                        {data.map((item, index) => {
                            return <Option
                                value={item.name}
                                className={styles.selectOption}
                                key={index}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                >
                                    {item.name}
                                    <button onClick={(e) => deleteItem(e, item.slug)} danger size="small">
                                        <AiOutlineMinus />
                                    </button>
                                </div>
                            </Option>
                        })}

                    </Select>
                </div>
                <Input
                    className={styles.fields}
                    placeholder="Enter Book Number Of Lesson"
                    value={book}
                    onChange={(e) => handleChange(e.target.value, setBook)}
                />
                <Input
                    className={styles.fields}
                    placeholder="Enter Name For Lesson"
                    value={bookname}
                    onChange={(e) => handleChange(e.target.value, setBookname)}
                />

                <Dragger {...props}
                >
                    {file ?
                        <>
                            <p className="ant-upload-drag-icon">
                                <img src={preview} alt="Preview Image" />
                            </p>
                            <p className="ant-upload-hint">
                                {file.name}
                            </p>
                        </> :
                        <>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Supported files format are xlsx, csv and xlx
                            </p>
                        </>
                    }
                </Dragger>
                <button className={styles.submit} onClick={handleUploadfile}>
                    Upload

                </button>

            </div>

        </div >
    )
}