import { Axios } from "../hooks/useAxiosInstance";
import { getToken } from "../Prototypes";

export const Config = (function () {


    const apis = () => ({
        upload: async ({ data, api_token }) => await addRecord(data, api_token),
        download: async () => await downloadFile(),
        reset: async () => await resetFile(),

    })
    async function addRecord(data, api_token) {

        const url = "/api/config";
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.patch(url, data, option);
    }

    async function downloadFile() {

        const url = "/api/config/download";
        const option = {
            headers: {
                'token': getToken(),
                'Content-Type': 'application/json',
            }
        }

        return await Axios.post(url, {}, option);
    }
    async function resetFile() {

        const url = "/api/config/reset";
        const option = {
            headers: {
                'token': getToken(),
                'Content-Type': 'application/json',
            }
        }

        return await Axios.post(url, {}, option);
    }


    return apis();

})()
