import { getToken } from "../Prototypes";
import { Axios } from "../hooks/useAxiosInstance";

export const Profile = (function () {


    const apis = () => ({
        post: async (data) => await postRecord(data),
        postForgetPassword: async (data) => await postForget(data),
        update: async (data, api_token) => await updatePassword(data, api_token)
    })

    async function postRecord(data) {
        const url = "api/user/login";
        const option = {
            headers: {
                'token': getToken(),
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }
    async function postForget(data) {
        const url = "api/admin/forget-password";
        const option = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    async function updatePassword(data, api_token) {
        const url = "api/user/change-password";
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    return apis();

})()
