import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Lookup } from '../Api/Lookup';
import { Toast } from '../hooks/useToast';
import { updateStatus } from './DashboardReducer';


export const getLanguageThunk = createAsyncThunk(
    'language/get',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data
            const response = await Lookup.fetch({ api_token });
            return response.data;
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message)
        }
    }
)


export const addLanguageThunk = createAsyncThunk(
    'language/create',
    async ({ data, setIsCategorySubmitted, categoryRef }, { getState }) => {
        try {
            Toast('Adding Language', "loading", true);
            const { api_token } = getState().profile.data
            const response = await Lookup.create({ data, api_token });
            categoryRef.current.value = "";
            Toast("Language added successfully", "success", false);
            return response.data;
        } catch (err) {
            console.log(err);
            Toast(err.message, "error", false);
            return Promise.reject(err.message)
        }
        finally {
            setIsCategorySubmitted(false)
        }
    }
)

export const deleteLanguageThunk = createAsyncThunk(
    'language/delete',
    async ({ slug, setIsDeleting }, { getState, dispatch }) => {
        Toast('Deleting language', "loading", false);
        try {
            const { api_token } = getState().profile.data;
            const response = await Lookup.delete({ slug, api_token });
            dispatch(updateStatus("updated"))
            Toast('Language deleted successfully', "success", false);
            return slug
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message)
        }
        finally {
            setIsDeleting(false)
        }
    }
)



const initialState = {
    language: {
        status: "pending",
        data: []
    }
}

export const lookupSlice = createSlice({
    name: 'lookup',
    initialState,
    reducers: {
        setStatus: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
        resetState: () => {
            return initialState
        }
    },
    extraReducers: {
        [getLanguageThunk.fulfilled]: (state, action) => {
            state.language.data = action.payload
            state.language.status = "success"
        },
        [getLanguageThunk.pending]: (state, action) => {
            state.language.status = "loading"
        },
        [getLanguageThunk.rejected]: (state, action) => {
            state.language.status = "error"
        },
        [addLanguageThunk.fulfilled]: (state, action) => {
            state.language.data = [
                action.payload,
                ...state.language.data,

            ]
            state.language.status = "success"
        },
        [addLanguageThunk.pending]: (state, action) => {
            state.language.status = "loading"
        },
        [addLanguageThunk.rejected]: (state, action) => {
            state.language.status = "error"
        },
        [deleteLanguageThunk.fulfilled]: (state, action) => {
            state.language.data = state.language.data.filter(item => item.slug != action.payload)
            state.language.status = "success"
        },
        [deleteLanguageThunk.pending]: (state, action) => {
            state.language.status = "loading"
        },
        [deleteLanguageThunk.rejected]: (state, action) => {
            state.language.status = "error"
        },
    }
})


// Action creators are generated for each case reducer function
export const { setStatus, resetState } = lookupSlice.actions

export default lookupSlice.reducer