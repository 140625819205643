import { Axios } from "../hooks/useAxiosInstance";
import { getToken } from "../Prototypes";

export const Lesson = (function () {


    const apis = () => ({
        upload: async ({ data, api_token }) => await addRecord(data, api_token),
        download: async ({ path }) => await downloadRecord(path),

    })
    async function addRecord(data, api_token) {
        const url = "/api/lesson";
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'multipart/form-data'
            }
        }

        return await Axios.post(url, data, option);
    }
    async function downloadRecord(path) {
        const url = "/api/lesson/download?path=" + path;
        const option = {
            headers: {
                'token': getToken(),
                'Content-Type': 'multipart/form-data'
            }
        }

        return await Axios.post(url, {}, option);
    }


    return apis();

})()
