import { Axios } from "../hooks/useAxiosInstance";

export const Lookup = (function () {


    const apis = () => ({
        fetch: async ({ api_token }) => await fetchRecord(api_token),
        create: async ({ data, api_token }) => await createRecord(data, api_token),
        delete: async ({ slug, api_token }) => await deleteRecord(slug, api_token),
    })

    async function fetchRecord(api_token) {
        const url = "api/language";
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.get(url, option);
    }


    async function createRecord(data = {}, api_token) {
        const url = "api/language/create";
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }
    async function deleteRecord(slug, api_token) {
        const url = "api/language/" + slug;
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.delete(url, option);
    }
    return apis();

})()
