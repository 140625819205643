import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import 'antd/dist/antd.min.css';

import ProtectedLayout from "./components/ProtectedLayout";
import ContentLayout from "./components/ContentLayout";

import DashBoard from "./pages/DashBoard";
import Login from "./pages/Login";
import PublicRoute from "./components/Routes/PublicRoute";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import ChangePassword from "./pages/ChangePassword";
import UploadLesson from "./pages/UploadLesson";
import ConfigManagement from "./pages/ConfigManagement";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path={`/login`} element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        } />
        <Route exact path={`/dashboard`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="0" isSearchBar={false}>
              <ContentLayout>
                <DashBoard />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>

        } />
        <Route exact path={`/lesson-management`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="1" isSearchBar={false}>
              <ContentLayout>
                <UploadLesson />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>

        } />
        <Route exact path={`/config-management`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="2" isSearchBar={false}>
              <ContentLayout>
                <ConfigManagement />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>

        } />
        <Route exact path={`/change-password`} element={
          <ProtectedRoute>
            <ProtectedLayout selectedRoute="3" isSearchBar={false} >
              <ContentLayout>
                <ChangePassword />
              </ContentLayout>
            </ProtectedLayout >
          </ProtectedRoute>
        } />


        <Route path={`/*`} element={<Navigate to={`/login`} />} />
      </Routes >


    </Router >
  );
}

export default App;
