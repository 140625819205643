import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedRoute({ children }) {

    const { isLoggedIn } = useSelector(state => state.default);


    return (
        isLoggedIn ? children : <Navigate to={`/login`} replace />

    );
}

export default ProtectedRoute;