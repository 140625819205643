import React from "react";
import { Link } from "react-router-dom"
import styles from "./ProtectedLayout.module.scss";
import Navbar from "./Navbar";


import { Layout } from 'antd';
import { confirmAlert } from 'react-confirm-alert';

/**Import Assets */
import { useDispatch } from "react-redux";
import { updateKey } from "../../features/DefaultReducer";
import link1 from "./images/1.svg";
import upload from "./images/upload.svg";
import config from "./images/config.svg";
import password from "./images/password.svg";
import link10 from "./images/10.svg";




import { resetState as profileReset } from "../../features/ProfileReducer";
import { resetState as dashboardReset } from "../../features/DashboardReducer";



const { Sider } = Layout;

export default function ProtectedLayout({ selectedRoute, isSearchBar, children }) {

    const dispatch = useDispatch();


    const items = [
        {
            icon: link1,
            label: "Dashboard"
        },
        {
            icon: upload,
            label: "Lesson Management"
        },
        {
            icon: config,
            label: "Config Management"
        },
        {
            icon: password,
            label: "Change Password"
        },


    ]

    function handleLogout() {
        dispatch(updateKey({ isLoggedIn: false }));
        dispatch(profileReset());
        dispatch(dashboardReset());

    }

    function submit(title, message, cb, rejectCB = () => { }) {
        confirmAlert({
            title,
            message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => cb()
                },
                {
                    label: 'No',
                    onClick: () => rejectCB()
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
        });
    }



    return (
        <div className={styles.protectedLayout}>
            <Sider trigger={null} style={{ height: "100vh" }} className={styles.sider} width={335} >
                <div className={styles.children}>

                    <Link className={styles.logo} to={`/dashboard`}>
                        <img src="logo.png" alt="Logo Image" />

                    </Link>
                    <div className={styles.menu}>
                        {items.map(({ icon, label }, index) => {
                            return <Link to={`/${label.toLowerCase().replace(/[^A-Z0-9]/ig, "-")}`} className={`${styles.item} ${index == selectedRoute ? styles.active : ""}`} key={index}>
                                <img src={icon} alt={`Icon${index}`} width={20} height={20} />
                                <p>{label}</p>
                            </Link>
                        })}
                        <Link className={styles.item} onClick={() => submit("Confirm Logout", "Are you sure you want to logout?", () => handleLogout())} >
                            <img src={link10} alt="Icon1" width={20} height={20} />
                            <p>Logout</p>
                        </Link>

                    </div>

                </div>


            </Sider >
            <div className={styles.routeContent}>
                <Navbar isSearchBar={isSearchBar} />
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </div >
    )
}