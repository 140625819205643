import React from "react";
import styles from "./ProtectedLayout.module.scss";
import moment from "moment"


import { FaCalendarAlt } from "react-icons/fa";

import { useDispatch } from "react-redux";



export default function Navbar({ isSearchBar }) {


    const dispatch = useDispatch();



    return (
        <div className={styles.Navbar}>
            <div className={styles.date}>
                <FaCalendarAlt />
                <p>{moment(new Date()).format('MMMM DD,  YYYY')}</p>
            </div>
        </div >
    )
}