
import React, { useState } from "react";


/**Import Styles And Assets */
import styles from "./styles/index.module.scss";


/**Import Package Components */
import { Toast } from "../../hooks/useToast";
import { Config } from "../../Api/Config";
import { Input } from 'antd';
import { useSelector } from "react-redux";
import fileDownload from 'js-file-download'



export default function ConfigManagement() {


    const INITIAL_STATE = {
        language: '',
        bookname: '',
        cover_url: '',
        book_level: '',
        book_number: '',
        total_lesson: '',
    }
    const [body, setBody] = useState(INITIAL_STATE);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isReseting, setIsReseting] = useState(false);
    const { api_token } = useSelector(state => state.profile.data);






    const handleUploadfile = async () => {
        if (isSubmitted) return;
        Toast("Uploading Config", 'loading', true);
        setIsSubmitted(true);
        try {
            const response = await Config.upload({ data: body, api_token });
            Toast(response.message, 'success', false);
            setBody(INITIAL_STATE);
        }
        catch (err) {
            Toast(err.message, 'error', false)
        }
        finally {
            setIsSubmitted(false);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBody(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleConfig = async () => {

        if (isDownloading) return;
        setIsDownloading(true)
        try {
            const response = await Config.download();
            fileDownload(JSON.stringify(response), 'config.json');
        }
        catch (err) {
            Toast(err.message, 'error', false)
        }
        finally {
            setIsDownloading(false);
        }
    }

    const handleResetConfig = async () => {

        if (isReseting) return;
        setIsReseting(true)
        Toast("Reseting config", 'loading', true);
        try {
            const response = await Config.reset();
            Toast(response.message, 'success', false);
        }
        catch (err) {
            Toast(err.message, 'error', false)
        }
        finally {
            setIsReseting(false);
        }
    }



    return (
        <div className={styles.uploadLessons}>
            <div className={styles.headerContainer}>
                <h1 className={styles.contentTitle}>Config Management</h1>
                <button className={styles.headerButton} onClick={handleResetConfig}>Reset Config</button>
                <button className={styles.headerButton} onClick={handleConfig}>Download Config</button>
            </div>
            <div className={styles.content}>
                <Input
                    name="language"
                    className={styles.fields}
                    placeholder="Language"
                    value={body.language}
                    onChange={handleChange}
                />
                <Input
                    name="bookname"
                    className={styles.fields}
                    placeholder="Book name"
                    value={body.bookname}
                    onChange={handleChange}
                />
                <Input
                    name="cover_url"
                    className={styles.fields}
                    placeholder="Cover Url"
                    value={body.cover_url}
                    onChange={handleChange}
                />
                <Input
                    name="book_level"
                    className={styles.fields}
                    placeholder="Book Level"
                    value={body.book_level}
                    onChange={handleChange}
                />
                <Input
                    name="book_number"
                    className={styles.fields}
                    placeholder="Book Number"
                    value={body.book_number}
                    onChange={handleChange}
                />
                <Input
                    name="total_lesson"
                    className={styles.fields}
                    placeholder="Total Lesson"
                    value={body.total_lesson}
                    onChange={handleChange}
                />

                <button className={styles.submit} onClick={handleUploadfile}>
                    Upload
                </button>

            </div>

        </div >
    )
}