import moment from "moment";

export const validateEmail = (value) => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return value.match(validRegex)
}


export const sliceText = (str, length) => {
    const val = str.slice(0, length);
    return val + ((str.length > length) ? "..." : "")
}

export const formattedDate = (date) => {
    return moment(date).format('DD MMM, YYYY')
}

export const roundOffTo = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier
}


export const baseUrl = () => {
    return process.env.REACT_APP_DOMAIN
}
export const staticToken = () => {
    return process.env.REACT_APP_TOKEN
}

export const getToken = () => {
    return process.env.REACT_APP_CLIENT_ID
}


export const validateType = (type) => {
    const types = ['application/vnd.ms-excel', 'text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
    return types.includes(type)
}
