import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardThunk } from "../../features/DashboardReducer";

/**Import Styles and Assets */
import styles from "./DashBoard.module.scss";
import image from "./images/image.svg"


/**Import From Antd */
import { Col, Row } from 'antd';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 35, color: "white" }} spin />;

export default function DashBoard() {

    const colors = ['#20C3FF', '#C04848', '#71B280', '#BA2D52', '#C33764', '#52C234'];
    const dispatch = useDispatch();
    const { data, status } = useSelector(state => state.dashboard);

    useEffect(() => {
        if (status !== "success") {
            dispatch(fetchDashboardThunk());
        }
    }, []);


    function getRandomInt(min = 0, max = 3) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    console.log(Object.keys(data));

    return (
        <div className={styles.dashBoard}>
            <h1 className={styles.contentTitle}>Dashboard</h1>
            <div className={styles.content}>
                <Row gutter={[16, 16]} className={styles.row}>
                    {!Object.keys(data).length ? <div className={styles.emptyContainer}>
                        <h1>No languages found</h1>
                    </div> :
                        Object.keys(data).map((i, index) => {
                            console.log(i);
                            return <Card name={i} value={data[i]} status={status} color={colors[getRandomInt()]} index={index} />
                        })}
                </Row>


            </div>

        </div>
    )
}

export function Card({ name, value, color, status, index }) {
    return (
        <Col xxl={6} xl={8} lg={10} sm={20} >
            <div className={styles.card} style={{ backgroundColor: color }}>
                <div className={styles.imageBack}>
                    <img src={image} alt={`Dash Board${index} Image`} />
                </div>
                <h1>{status === "loading" ? <Spin indicator={antIcon} size="large" /> : value}</h1>
                <h4>{`Number of ${name} books`}</h4>

            </div >
        </Col >
    )
}