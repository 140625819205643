import { configureStore } from '@reduxjs/toolkit';
import DashboardReducer from './features/DashboardReducer';

import defaultReducer from './features/DefaultReducer';
import ProfileReducer from './features/ProfileReducer';
import lookupReducer from './features/lookupReducer';

export const store = configureStore({
  reducer: {
    default: defaultReducer,
    dashboard: DashboardReducer,
    profile: ProfileReducer,
    lookup: lookupReducer
  },
})